@if(formGroup(); as form)
{   
    @if(form.get(id()); as nameControl)
    {    
        <div [id]="id() + '-errors'">
            @if(nameControl.invalid && (nameControl.dirty || nameControl.touched))
            {
                @if (nameControl.errors['required'])
                {                            
                    <required-validation-error [label]="label()" />
                }
                @if (nameControl.errors['nameTaken'])
                {
                    <name-taken-validation-error [label]="label()" />
                }            
            }
       </div>
    }
}