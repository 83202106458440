import { WritableSignal, signal } from "@angular/core";

export enum ColumnSortOrder {
  None = 0,
  Ascending = 1,
  Descending = 2,
}

export class Column {

  public id: string;
  public columnText: WritableSignal<string>;
  public width?: WritableSignal<string>;
  public sortOrder?: WritableSignal<ColumnSortOrder>;

  constructor(id: string, columnText: string, width?: string, sortOrder?: ColumnSortOrder) {
    this.id = id;
    this.columnText = signal<string>(columnText || null);
    this.width = signal<string>(width || '33.33%');
    this.sortOrder = signal<ColumnSortOrder>(sortOrder || ColumnSortOrder.None);
  }
}
