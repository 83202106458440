export * from './presenter';
export * from './breadcrumb'
export * from './forms';
export * from './page-not-found';
export * from './filter';
export * from './list';
export * from './data-table';

import { NavigationComponents } from './navigation';
import { BreadcrumbComponents, BreadcrumbServices } from './breadcrumb';
import { FormComponents } from './forms';
import { FilterComponents  } from './filter';
import { PageNotFoundComponent } from './page-not-found';
import { PresenterComponent } from './presenter';
import { ListComponents } from './list';
import { DataTableComponents } from './data-table';

export const SharedComponents = [
    ...NavigationComponents,
    ...FilterComponents,
    ...BreadcrumbComponents,
    ...FormComponents,
    ...ListComponents,
    ...DataTableComponents,
    PageNotFoundComponent,
    PresenterComponent
]

export const SharedComponentServices = [
    ...BreadcrumbServices
]