import {
  Cell,
  ColumnGroup,
  Column,
  Table,
  Row
} from '@app/shared';

export class DataSource {
  static getData() {
    let table = new Table();
    let columnGroup1 = new ColumnGroup();
    columnGroup1.id = 'department';
    columnGroup1.isSelected = true;///// can be used to show hide columnGroups
    let column1 = new Column('code', 'Code');
    let column2 = new Column('name', 'Name');
    columnGroup1.columns = [column1, column2];

    let columnGroup2 = new ColumnGroup();
    columnGroup2.id = 'Room';
    columnGroup2.isSelected = true;///// can be used to show hide columnGroups
    let column3 = new Column('code', 'Code');
    let column4 = new Column('subCode', 'Sub Code');
    let column5 = new Column('name', 'Name');
    columnGroup2.columns = [column3, column4, column5];

    let cell1 = new Cell('ADMECX');
    let cell2 = new Cell('Administration, Executive Suite ');

    let cell3 = new Cell('AAMEN');
    let cell4 = new Cell('XXXX');
    let cell5 = new Cell('Alcove, Amenity');
    let row1 = new Row();
    let row2 = new Row();
    row1[column1.id] = cell1;
    row1[column2.id] = cell2;
    

    row2[column3.id] = cell3;
    row2[column4.id] = cell4;
    row2[column5.id] = cell5;

    table.columngroups = [columnGroup1, columnGroup2];
    table.columngroups[0].rows = [row1];
    table.columngroups[1].rows = [row2];

    return table;
  }
}
