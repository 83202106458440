
<button
    accordion-heading
    class="btn btn-link d-flex w-100 justify-content-between align-items-center"        
    type="button">
    <span>
        <img
            src="assets/images/filter.png"
            [ngClass]="isRoot() ? 'filter-icon-main' : 'filter-icon'" 
            class="me-2" />
        <img
            src="assets/images/filter-blue.png"
            [ngClass]="isRoot() ? 'active-filter-icon-main' : 'active-filter-icon'" 
            class="me-2" />
        <span [ngClass]="isRoot() ? 'filter-name-main' : 'filter-name'" >{{groupName()}}</span>
    </span>
    <span [ngClass]="isRoot() ? 'closed-outer' : 'closed'" class="material_icons_round material-icons">keyboard_arrow_right</span>
    <span [ngClass]="isRoot() ? 'opened-outer' : 'opened'" class="material_icons_round material-icons text-light-blue">keyboard_arrow_down</span>
</button>
