//angular imports
import {
    Injectable
} from '@angular/core';

//local imports

import {
    IBreadcrumbItemModel
} from '@app/shared';


export type BreadcrumbTracker =
    {
        breadcrumbName: string;
        breadcrumbs: IBreadcrumbItemModel[]
    }

@Injectable({
    providedIn: 'root'
})
export class BreadcrumbStateService {
    public breadcrumbTracker: BreadcrumbTracker[] = [];

    public getTracker(breadcrumbName: string) {
        let trackers = this.breadcrumbTracker.filter(t => t.breadcrumbName === breadcrumbName);
        let tracker = trackers.length > 0 ? trackers[0] : null;

        if (!tracker) {
            tracker = {
                breadcrumbName: breadcrumbName,
                breadcrumbs: []
            };

            this.breadcrumbTracker.push(tracker);
        }

        return tracker;
    }
}
