<div class="font-20 d-flex text-white position-relative">
    <div class="popup-title p-4 d-inline-block bg-dark-blue2">{{title()}}</div>
    <div class=" flex-grow-1 position-relative bg-dark-blue2">
        <div class="position-absolute font-14 w-100 text-red popup-curve px-4 w-75 pb-0 end-0 text-end bg-dark-blue bottom-0 pt-3">
            @if(hasMandatoryFields())
            {
                <span class="font-12">Mandatory Fields*</span>
            }
            @else {
                <span>&nbsp;</span>
            }
        </div>
    </div>
</div>