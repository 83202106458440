import { Subject } from "rxjs";

/**
 * @summary Contains the Promise for the http request, and a cancellation token to allow cancellation for long running requests
 */
export class HttpResult<T>
{
    public Cancellation: Subject<boolean>;
    public Response: Promise<T>;

    constructor()
    {
        this.Cancellation = new Subject<boolean>();
    }
}