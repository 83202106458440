//angular imports
import { Component, EventEmitter, Output } from '@angular/core';


@Component({
  selector: 'list-filter',
  templateUrl: './list-filter.component.html',
  styleUrl: './list-filter.component.scss'
})
export class ListFilterComponent {
          
  @Output()
  updateFilter = new EventEmitter<any>();
  
  searchEntity(event: any) 
  {
    this.updateFilter.emit(event);    
  }
}
