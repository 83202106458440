import { ComponentType } from "@angular/cdk/portal";

export type PresenterRegistryItem = 
{
    /**
    * A key to create an override template for a specific use case
    */
    key?: string,        

    /**
     * The model associated with the template
     */
    model: Function,

    /**
     * The template
     */
    component: ComponentType<unknown>
}

export type PresenterModelKey = 
{
   /**
    * The model to associate with the presenter
    */
   model: Function,
   /**
    * A key to create an override template for a specific use case
    */
   key?: string, 
}


/**
 * @summary Decorator to mark a class as a presenter type
 */
export function PresenterType(input: { key?: string, model: Function }) {

  return function (target: ComponentType<unknown>) 
  {     
    PresenterHelper.register(input, target);
  };
}

/**
 * @summary Pulls the type information from the presenterType decorator
 */
export class PresenterHelper {

  private static registry: PresenterRegistryItem[] = [];

  /**
   * Register the template with a model
   * @param metadata for the registration 
   * @param the component to register
   */
  public static register(input: PresenterModelKey, target: ComponentType<unknown>) {

    PresenterHelper.registry.push({ 
        key: input.key, 
        model: input.model, 
        component: target 
    });

    console.debug(`Registered presenter type for ${input.model.constructor.name} ${input.key ?? ''} with constructor ${target.name}`);
  }

  /**
   * Get a template for a model
   * @param lookup metadata
   * @returns the registered component
   */
  public static getTemplate(input: PresenterModelKey): ComponentType<unknown> {
        
    let registryMatch = PresenterHelper.registry.find(i=>
    {
        if (input.key && !i.key || !input.key && i.key) return false;
        if (input.key && i.key && input.key.toLowerCase() !== i.key.toLowerCase()) return false;
                
        return i.model === input.model.constructor;
    });
    
    return registryMatch?.component;
  }
}

