//angular imports
import {
  ChangeDetectionStrategy,
  Component,
  input
} from '@angular/core';

//local imports

import {
  IBreadcrumbItemModel
} from '@app/shared';

@Component({
  selector: 'breadcrumb-item',
  templateUrl: './breadcrumb-item.component.html',
  styleUrl: './breadcrumb-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbItemComponent {
    
  public data = input<IBreadcrumbItemModel>(null);  
  
}
