export const ReadRetryCodes = [404, 408, 421, 520, 503];
export const WriteRetryCodes = [404, 503];

/**
 * @summary Provides a default retry policy for API requests
 */
export class DefaultApiRetryPolicy {

    public maxRetryAttempts = 5;

    public handleError(httpMethod: string, error: any) {
        let retryCodes = httpMethod === "GET" ? ReadRetryCodes : WriteRetryCodes;
        return error.status && !!retryCodes.find(code => code === error.status);
    }
}