//angular
import { Platform } from "@angular/cdk/platform";
import { Injectable, OnDestroy } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

//rxjs
import { Subject, filter, takeUntil } from "rxjs";

//Msal
import { 
    BrowserCacheLocation, 
    LogLevel, 
    ProtocolMode, 
    IPublicClientApplication, 
    PublicClientApplication,
    InteractionType, 
    Configuration,
    EventType, 
    EventMessage, 
    AuthenticationResult, 
    RedirectRequest 
} from '@azure/msal-browser';

import { 
    MsalInterceptorConfiguration, 
    MsalGuardConfiguration, 
    ProtectedResourceScopes, 
    MsalService, 
    MsalBroadcastService 
} from '@azure/msal-angular';

//local
import { environment } from '@env/environment';
import { Messenger } from "@app/shared";
import { 
    UserLoggedInMessage,
    SetPreviousUrlMessage 
} from "@core/messages";

export class SecurityConfig {

    applicationUri: string;
    appId: string;
    appName: string;
    stage: string;
    prefix: string;

    azureB2c: {
        instance: string;
        domain: string;
        signUpSignIn: string;
        resetPassword: string;
        editProfile: string;
    };
}

export interface ProtectedResources {
    applicationApi:
    {
        endpoint: string;
        scopes: {
            read: string[];
            write: string[];
        };
    };
}

@Injectable({
    providedIn: 'root'
})
export class ApplicationSecurityLifetime implements OnDestroy {
    private readonly destroying$ = new Subject<void>();
    private authSubscription: any;
    private forgotPasswordSubscription: any;

    private previousUrl: string = null;
    private currentUrl: string = null;

    public isIframe: boolean = false;

    constructor(
        private authService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private security: ApplicationSecurity,
        private router: Router,
        private messenger: Messenger,
        private platform: Platform) { }

    initialize() {

        this.isIframe = this.getIsIframe();

        // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events
        // emitted when a user logs in or out of another tab or window
        this.authService.instance.enableAccountStorageEvents();

        this.authSubscription = this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS || msg.eventType === EventType.SSO_SILENT_SUCCESS),
                takeUntil(this.destroying$)
            )
            .subscribe(async (result: EventMessage) => {
                // Casting payload as AuthenticationResult to access account                
                if (result.payload && result.payload['authority'].indexOf('passwordreset') > -1) {
                    this.authService.logout();
                    return;
                }

                const authenticationResult = result.payload as AuthenticationResult;
                
                this.authService.instance.setActiveAccount(authenticationResult.account);

                this.messenger.send(new UserLoggedInMessage(authenticationResult.account));
            });

        this.forgotPasswordSubscription =
            this.msalBroadcastService.msalSubject$
                .pipe(
                    filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
                    takeUntil(this.destroying$)
                )
                .subscribe((result: EventMessage) => {
                    // Checking for the forgot password error. Learn more about B2C error codes at
                    // https://learn.microsoft.com/azure/active-directory-b2c/error-codes
                    if (result.error && result.error.message.indexOf('AADB2C90118') > -1) {
                        let resetPasswordFlowRequest: RedirectRequest = {
                            authority: this.security.ResetPasswordAuthority,
                            scopes: [],
                        };
                        this.authService.loginRedirect(resetPasswordFlowRequest);
                        return;
                    };
                });

        this.HandleNavigation();
    }

    private HandleNavigation() {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                this.previousUrl = this.currentUrl;
                this.currentUrl = event.url;
                this.messenger.send(new SetPreviousUrlMessage(this.previousUrl!));
            });
    }

    // unsubscribe to events when component is destroyed
    ngOnDestroy(): void {

        this.destroying$.next(undefined);

        this.authSubscription.unsubscribe();
        this.forgotPasswordSubscription.unsubscribe();

        this.destroying$.complete();
    }


    private getIsIframe(): boolean {
        
        if (this.platform.isBrowser) {
            return window !== window.parent && !window.opener;
        } else {
            return false;
        }
    }


}

@Injectable({
    providedIn: 'root'
})
export class ApplicationSecurity {
    public readonly msalConfig: Configuration;

    public SignUpSignInAuthority: string;
    public ResetPasswordAuthority: string;

    constructor(
        private securityConfig: SecurityConfig,
        private platform: Platform) {
        this.SignUpSignInAuthority = `https://${this.securityConfig.azureB2c.instance}/${this.securityConfig.azureB2c.domain}/${this.securityConfig.azureB2c.signUpSignIn}`;
        this.ResetPasswordAuthority = `https://${this.securityConfig.azureB2c.instance}/${this.securityConfig.azureB2c.domain}/${this.securityConfig.azureB2c.resetPassword}`;


        this.msalConfig = {
            auth: {
                clientId: this.securityConfig.appId, // This is the ONLY mandatory field that you need to supply.

                //b2cPolicies.authorities.signUpSignIn.authority
                // Defaults to "https://login.microsoftonline.com/common"
                authority: this.SignUpSignInAuthority,
                protocolMode: ProtocolMode.AAD,
                knownAuthorities: [this.securityConfig.azureB2c.instance], // Mark your B2C tenant's domain as trusted.
                redirectUri: "/auth", // Points to window.location.origin by default. You must register this URI on Azure portal/App Registration.
                postLogoutRedirectUri: "/", // Points to window.location.origin by default.        
                //navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.                
            },
            cache: {
                cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
                storeAuthStateInCookie: this.platform.TRIDENT, // Set this to "true" if you are having issues on IE11 or Edge. Remove this line to use Angular Universal
            },
            system: {
                /**
                 * Below you can configure MSAL.js logs. For more information, visit:
                 * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
                 */
                //allowNativeBroker: false, // Disables WAM Broker
                loggerOptions: {
                    loggerCallback(logLevel: LogLevel, message: string) {

                        switch (logLevel) {
                            case LogLevel.Error:
                                console.error(message);
                                return;
                            case LogLevel.Info:
                                console.info(message);
                                return;
                            case LogLevel.Verbose:
                                console.debug(message);
                                return;
                            case LogLevel.Warning:
                                console.warn(message);
                                return;
                        }
                    },
                    logLevel: environment.production ? LogLevel.Warning : LogLevel.Verbose,//TODO:DIW:Allow config
                    piiLoggingEnabled: false
                }
            }

        }
    }
    
    /**
     * Here we pass the configuration parameters to create an MSAL instance.
     * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
     */
    public MSALInstanceFactory(): IPublicClientApplication {
        return new PublicClientApplication(this.msalConfig);
    }


    /**
     * MSAL Angular will automatically retrieve tokens for resources
     * added to protectedResourceMap. For more info, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
     */
    public MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
        const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();

        let protectedResources = this.getProtectedResources();

        protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
        protectedResourceMap.set(`${this.msalConfig.auth.authority}/openid/v2.0/userinfo`, [...protectedResources.applicationApi.scopes.read]);

        protectedResourceMap.set(protectedResources.applicationApi.endpoint,
            [
                {
                    httpMethod: 'GET',
                    scopes: [...protectedResources.applicationApi.scopes.read]
                },
                {
                    httpMethod: 'POST',
                    scopes: [...protectedResources.applicationApi.scopes.write]
                },
                {
                    httpMethod: 'PUT',
                    scopes: [...protectedResources.applicationApi.scopes.write]
                },
                {
                    httpMethod: 'DELETE',
                    scopes: [...protectedResources.applicationApi.scopes.write]
                },
                {
                    httpMethod: 'PATCH',
                    scopes: [...protectedResources.applicationApi.scopes.write]
                }
            ]);

        return {
            interactionType: InteractionType.Redirect,
            protectedResourceMap,
        };
    }

    /**
     * Set your default interaction type for MSALGuard here. If you have any
     * additional scopes you want the user to consent upon login, add them here as well.
     */
    public MSALGuardConfigFactory(): MsalGuardConfiguration {
        return {
            interactionType: InteractionType.Redirect,
            authRequest: this.getLoginRequest(),
            //loginFailedRoute: '/login-failed'
        };
    }

    /**
     * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
     */
    public getProtectedResources(): ProtectedResources {
        return {
            applicationApi: {
                endpoint: `${this.securityConfig.applicationUri}`,
                scopes: {
                    read: [`https://${this.securityConfig.azureB2c.domain}/${this.securityConfig.appName}/api`],
                    write: [`https://${this.securityConfig.azureB2c.domain}/${this.securityConfig.appName}/api`]
                }
            }
        }
    }

    /**
    * Scopes you add here will be prompted for user consent during sign-in.
    * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
    * For more information about OIDC scopes, visit:
    * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
    */
    private getLoginRequest(): { scopes: string[]; } {
        let protectedScopes = this.getProtectedResources();

        return {
            scopes: [
                "openid",
                "profile",
                "email",
                ...protectedScopes.applicationApi.scopes.read,
                ...protectedScopes.applicationApi.scopes.write,
            ]
        };
    }
}