import { IBreadcrumbItemModel } from "../models/breadcrumb-item.model";

export class BreadcrumbItemToggledMessage
{
    public breadcrumbItem: IBreadcrumbItemModel;
    public breadcrumbName: string;
    public handled: boolean = false;
    
    constructor();
    constructor(breadcrumbItem: IBreadcrumbItemModel);
    constructor(breadcrumbItem?: IBreadcrumbItemModel, breadcrumbName?: string) {

        if (breadcrumbItem !== undefined)
        {
            this.breadcrumbItem = breadcrumbItem;
        }

        if (breadcrumbName !== undefined)
        {
            this.breadcrumbName = breadcrumbName;
        }
    }
}