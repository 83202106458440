//angular imports
import { ChangeDetectionStrategy, Component } from '@angular/core';

// //local imports
// import { Messenger } from '@shared/services';
// import { ToggleFilterMessage, UserCountChangedMessage } from '@app/security';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {

}


