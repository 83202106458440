import {  Component, EventEmitter, Output, input } from '@angular/core';

@Component({
  selector: 'form-dialog-standard-submit-buttons',
  templateUrl: './form-dialog-standard-submit-buttons.component.html',
  styleUrl: './form-dialog-standard-submit-buttons.component.scss',
})
export class FormDialogStandardSubmitButtonsComponent {
  
  public actionButtonName = input<string>(null);  
  public closeBtnName = input<string>(null);
  public enableSubmitButton = input<boolean>(true);
  public isValid = input<boolean>(true);
  public isBusy = input<boolean>(false);
  
  @Output() 
  public submit = new EventEmitter();

  @Output() 
  public cancel = new EventEmitter();
    
  public cancelClicked() {
    this.cancel.emit();
  }

  public submitClicked() {
    this.submit.emit();    
  }

}