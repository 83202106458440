import { AccountInfo } from "@azure/msal-browser";

export class UserLoggedInMessage
{
    public User: AccountInfo;

    constructor();
    constructor(user: AccountInfo);
    constructor(user?: AccountInfo) {

        if (user !== undefined)
        {
            this.User = user;
        }
    }
}