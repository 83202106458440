import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//msal
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';

//local
import { PageNotFoundComponent } from '@shared/components';
import { LandingComponent } from '@app/pages';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/landing' //TODO:DIW:Map to a core landing page instead of security
  },
  {
    path: 'security',
    loadChildren: () => import('./security/security.module').then(m => m.SecurityModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'landing',
    component: LandingComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'macd',
    loadChildren: () => import('./macd/macd.module').then(m => m.MACDModule),
    canActivate: [MsalGuard]
  },
  {
    // Needed for handling redirect after login
    path: 'auth',
    component: MsalRedirectComponent
  },
  //otherwise redirect to login
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      enableViewTransitions: true,
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }


