<div class="animate__animated animate__fadeInUp animate__faster">
    <list-filter (updateFilter)="searchEntity($event, 'searchValue')" />

    <div class="position-relative">
        <div class="position-absolute legend-like-header">
            <div class="font-12 text-white px-1">
                {{ title() }}
            </div>
        </div>
    </div>
    <div class="padding-1 h-100 clip4 bg-gradient-blue">
        <div class="clip4 h-100 user-card p-2 overflow-wrap">
            <div class="d-flex flex-column py-2">
                @for (entity of entitiesCopy(); track entity; let i = $index) {
                    <span
                        role="button"
                        (click)="$event.stopPropagation(); onSelectEntity(entity)">
                        <ng-container
                            [ngTemplateOutlet]="template()"
                            [ngTemplateOutletContext]="{ $implicit: entity, item: entity }" />
                    </span>
                } @empty {
                    <!--//TODO:DIW:Allow usage of custom empty template-->
                    <div class="alert animate__animated animate__fadeInUp animate__faster px-3 rounded-0 clip3 py-1 alert-warning d-flex align-items-center">
                        <span class="material-icons font-30 me-2">warning</span>
                        No Data Found
                    </div>
                }
            </div>
        </div>
    </div>
</div>
