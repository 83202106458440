//angular imports
import {
    Component, 
    WritableSignal, 
    signal,
} from '@angular/core';

//local imports
import { FilterGroupModel } from '@shared/components/filter';
import { PresenterType } from '@shared/components';

@PresenterType(
{
    model: FilterGroupModel
})
@Component({
    selector: 'filter-group-template',
    templateUrl: './filter-group-template.component.html'
})
export class FilterGroupTemplateComponent
{
    public data: WritableSignal<FilterGroupModel>;
    
    constructor() {
        this.data = signal<FilterGroupModel>(null);
    }
}