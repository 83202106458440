//angular imports
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  input
} from '@angular/core';

import {
  FormGroup
} from '@angular/forms';

@Component({
  selector: 'checkbox',
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush  
})
export class CheckboxComponent {

  public id = input.required<string>();
  public formGroup = input<FormGroup>();
  public checked = input<boolean>(false);
  public innerClass = input<string>('');

  @Output()
  public change = new EventEmitter();

  constructor() { }


}
