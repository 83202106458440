//angular imports
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  InputSignal,
  input,
  OnInit,
  OnDestroy
} from '@angular/core';

import {
  FormGroup
} from '@angular/forms';

//rxjs imports
import {
  Subject,
  takeUntil
} from 'rxjs';

@Component({
  selector: 'basic-validation-errors',
  templateUrl: './basic-validation-errors.component.html',
  styleUrl: './basic-validation-errors.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BasicValidationErrorsComponent implements OnInit, OnDestroy {

  private readonly destroying$ = new Subject<void>();

  public id = input.required<string>();
  public formGroup = input.required<FormGroup>();
  public label: InputSignal<string> = input.required<string>();

  constructor(private cdf: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.formGroup().statusChanges
      .pipe(takeUntil(this.destroying$))
      .subscribe(c => {
        this.cdf.markForCheck();
      });
  }

  ngOnDestroy(): void {

    this.destroying$.next(undefined);
    this.destroying$.complete();
  }
}
