//angular imports
import {
    Component, 
    WritableSignal, 
    signal,
} from '@angular/core';

//local imports
import {
    BreadcrumbItemToggledMessage,
    IBreadcrumbItemModel,
    Messenger,
    NodeModel
} from '@app/shared';

import { PresenterType } from '@shared/components';

@PresenterType(
{
    model: NodeModel<IBreadcrumbItemModel>
})
@Component({
    selector: 'filter-item-template',
    templateUrl: './filter-item-template.component.html'
})
export class FilterItemTemplateComponent
{
    public data: WritableSignal<NodeModel<IBreadcrumbItemModel>>;

    constructor(private messenger: Messenger) {
        this.data = signal<NodeModel<IBreadcrumbItemModel>>(null);
    }

    public toggleBreadcrumb(obj: IBreadcrumbItemModel) {
        this.messenger.send(new BreadcrumbItemToggledMessage(obj));
    }
}