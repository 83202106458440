<button 
    type="button" 
    (click)="submit($event)" 
    [disabled]="isBusy() || disabled()"
    [class]="css()">
    
    @if (isBusy())
    {
        <div class="spinner-border text-white spinner-border-sm" role="status"> <span class="visually-hidden">Loading...</span></div>                        
    }
    
    <ng-content></ng-content>

</button>