import { WritableSignal, signal } from "@angular/core";
import { NodeModel, IBreadcrumbItemModel } from "@app/shared";

export class FilterGroupModel extends NodeModel<FilterGroupModel | IBreadcrumbItemModel>
{    
    public title: WritableSignal<string>;
    
    constructor (input: { id?: string, title?: string, depth: number, children?: NodeModel<FilterGroupModel | IBreadcrumbItemModel>[] }) {
        super({ id: input.id, data: null, depth: input.depth, children: input.children });
        this.id = input.id;
        this.title = signal<string>(input.title);        
    }

        
}
