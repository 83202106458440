@if(formGroup().get(id()); as nameControl)
{
    @if(nameControl.invalid && (nameControl.dirty || nameControl.touched))
    {
        <div [id]="id() + '-errors'">

            @if (nameControl.errors['required'])
            {
                <required-validation-error [label]="label()" />                
            }

        </div>
    }
}