//angular imports
import {
  ChangeDetectionStrategy,
  Component,
  input
} from '@angular/core';

import {
  FormGroup
} from '@angular/forms';

import { Transforms } from '@app/shared';

@Component({
  selector: 'text-input',
  templateUrl: './text-input.component.html',
  styleUrl: './text-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextInputComponent  {

  public id = input.required<string>();
  public formGroup = input.required<FormGroup>();  
  public label = input.required<string>();
  public required = input(false, {
    transform: Transforms.toBoolean
  });

  constructor() {}
  
}
