@if (data(); as node) {
    @if (node.data(); as nodeData) {
        <checkbox
            [id]="node.id + '-filter-item'"
            [checked]="nodeData.isChecked()"
            [innerClass]="'ps-' + node.depth()"
            [attr.data-type]="node.metadata['type']"
            (change)="toggleBreadcrumb(nodeData)">
            <div
                style="max-width: 170px;"
                [title]="nodeData.name()"
                class="font-14 text-faded fw-light text-truncate">
                {{ nodeData.name() }}
            </div>
        </checkbox>
    }

    @for (child of node.children(); track child) {
        <presenter [data]="child" />
    }
}
