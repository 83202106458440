//angular imports
import { 
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    InputSignal, 
    effect, 
    input 
} from "@angular/core";

import { 
    CdkPortalOutletAttachedRef, 
    ComponentPortal 
} from "@angular/cdk/portal";

//local imports
import { PresenterHelper } from "./presenter-type";

@Component({
    selector: "presenter",
    templateUrl: "./presenter.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PresenterComponent
{    
    public presenterOutlet: ComponentPortal<any>;
    
    public data: InputSignal<any> = input<any>();
    public key: InputSignal<string> = input<string>();

    constructor(cdf: ChangeDetectorRef) {

        effect(() =>
        {        
            let data = this.data();
            if (!data) return null;

            let key = this.key();

            let template = PresenterHelper.getTemplate({ 
                model: data,
                key: key
            });

            this.presenterOutlet = new ComponentPortal(template);
            cdf.markForCheck();
        });
    }

    public attached(ref: CdkPortalOutletAttachedRef)
    {
        ref = ref as ComponentRef<any>;
        ref.instance?.data?.set(this.data());        
    }
}