import { Component, input } from '@angular/core';

@Component({
  selector: 'navigation-item',
  templateUrl: './navigation-item.component.html',
  styleUrl: './navigation-item.component.scss'
})
export class NavigationItemComponent {

  public selectedImageSrc = input.required<string>();  
  public unselectedImageSrc = input.required<string>();
  public selectedCss = input.required<string>();
  public unselectedCss = input.required<string>();
  public title = input.required<string>();
  public routerLink = input.required<string>();
}
