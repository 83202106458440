//angular imports
import {
    WritableSignal,
    Signal,
    signal
} from "@angular/core";

//local imports
export interface IListItem {
    id: string;
    isChecked: WritableSignal<boolean>;
    name: Signal<string>;
    select: (isSelected: boolean) => void;
}

export class ListItem<TListItemType> implements IListItem {
    id: string;
    name: Signal<string>;
    isChecked: WritableSignal<boolean>;
    value: TListItemType;

    constructor(name: Signal<string>, id: string, value: TListItemType, isSelected: boolean = false) {
        this.name = name;
        this.id = id;
        this.value = value;
        this.isChecked = signal<boolean>(isSelected);
    }

    public select(val: boolean) {
        this.isChecked.set(val ? true : false);
    }
}