//angular imports
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  InputSignal,
  OnDestroy,
  OnInit,
  input
} from '@angular/core';

import {
  FormGroup
} from '@angular/forms';

//rxjs imports
import {
  Subject,
  takeUntil
} from 'rxjs';

@Component({
  selector: 'name-validation-errors',
  templateUrl: './name-validation-errors.component.html',
  styleUrl: './name-validation-errors.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NameValidationErrorsComponent implements OnInit, OnDestroy {

  private readonly destroying$ = new Subject<void>();

  public id = input.required<string>();
  public formGroup = input.required<FormGroup>();
  public label: InputSignal<string> = input.required<string>();
  
  constructor(private cdf: ChangeDetectorRef) {}

  ngOnInit(): void {
      this.formGroup().statusChanges
      .pipe(takeUntil(this.destroying$))
      .subscribe(c => {        
        this.cdf.markForCheck();       
      });
  }

  ngOnDestroy(): void {

    this.destroying$.next(undefined);
    this.destroying$.complete();
  }
  
}
