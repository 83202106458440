<div class="container p-0 pb-3">
    <div class="row">
        <div class="col-6">
            <button id="cancel-button" 
                    type="button" 
                    (click)="cancelClicked()" 
                    [disabled]="isBusy()"
                    class="btn w-100 clip3 corner position-relative border-light-blue text-light-blue font-12 rounded-0 py-2">
                <span>{{closeBtnName()}}</span>
            </button>
        </div>

        @if (enableSubmitButton())
        {
            <div class="col-6">  
                <is-busy-button id="submit-button"
                                (execute)="submitClicked()" 
                                [isBusy]="isBusy()"
                                [disabled]="!isValid()" 
                                css="btn w-100 clip3 corner position-relative border-light-blue bg-light-blue bg-light-blue font-12 rounded-0 py-2">
                    <span>{{actionButtonName()}}</span>
                </is-busy-button>                            
            </div>
        }
    </div>
</div>