import { Column } from "@app/shared";

export class SortColumnMessage
{
    public column: Column;

    constructor(column) {

        this.column = column;
    }
}