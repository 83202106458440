//angular imports
import {
  ChangeDetectionStrategy,
  Component,
  InputSignal,
  input
} from '@angular/core';

@Component({
  selector: 'name-taken-validation-error',
  templateUrl: './name-taken-validation-error.component.html',
  styleUrl: './name-taken-validation-error.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NameTakenValidationErrorComponent  {

  public label: InputSignal<string> = input.required<string>();
  
  constructor() {}  
}
