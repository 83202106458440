const REGISTRY: { [name: string]: any } = {};

/**
 * @summary Decorator to mark a class as a fault type
 * @param The class name of the fault sent by the server
 */
export function Fault(name: string) {
    
  return function (target: Function) {        
    REGISTRY[name] = target;
    console.debug(`Registered fault type ${name} with constructor ${target.name}`);
  };
}

/**
 * @summary Helper class to work with faults
 */
export class FaultHelper {

  /**
   * @summary Check if the data is a web fault
   * @param json string containing the potential fault information
   * @returns true/false
   */
  public static isWebFault(data: any): boolean {

    if (!data) return false;

    try {
      let exception = JSON.parse(data);
      if (!exception.$type) return false;

      return exception.$type.startsWith("Modutecture.Shared.Http.WebApiFaultException");
    }
    catch{
      return false;
    }    
  }

  /**
   * @summary Create a fault instance from a JSON string
   * @param json string containing the fault information
   * @returns {TFaultType} The fault instance
   */
  public static createFaultInstance<TFaultType extends AbstractFault>(input: { data: any } ): TFaultType {
            
    if (!FaultHelper.isWebFault(input.data)) return null;

    let webApiFaultException = JSON.parse(input.data);
    
    let fault = JSON.parse(webApiFaultException.Fault);
    
    let type = fault.$type;
        
    for (const [key, cls] of Object.entries(REGISTRY)) {

      
      if (key.toLowerCase() == type.toLowerCase()) {     
        console.debug(`Found ${key} in fault registry with constructor ${cls}`);
        return Object.assign(new cls(), fault) as TFaultType;
      }
    }

    return null;
  }
}

/**
 * @summary Base class for all faults
 */
export abstract class AbstractFault { }