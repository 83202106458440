//angular imports
import { 
  Component, 
  Input, 
  OnInit, 
  WritableSignal, 
  input, 
  signal 
} from '@angular/core';

//syncfusion imports

//local imports
import { 
  Column,
  ColumnGroup,
  DataSource, 
  Messenger, 
  Row, 
  Table 
} from '@app/shared';

import { SortColumnMessage } from '../../messages';

@Component({
  selector: 'data-table',
  templateUrl: './data-table.component.html',
  styleUrl: './data-table.component.scss'
})
export class DataTableComponent implements OnInit {
    
  public allowSorting = input<boolean>(true);
  public table = signal<Table>(null);

  constructor(private messenger: Messenger) {}

  ngOnInit() {    
    this.table.set(DataSource.getData());
  }

  public sortColumn(column: Column) {
    this.messenger.send(new SortColumnMessage(column));
  }
  
  public hideColumn(columnGroupId, columnId) {
    let table = DataSource.getData();
    table.columngroups = table.columngroups?.map(
      (columngroup: ColumnGroup) => {
        if(columngroup.id == columnGroupId) {
          columngroup.columns = columngroup.columns.filter((column:Column) => {
            return column.id !== columnId
          })
          columngroup.rows = columngroup.rows.map(
            (row: Row) => {
                delete row[columnId];
                return row
            }
          )
        }
        return columngroup
      }
    );
    console.log(table)
    this.table.set(table);     
  }

}
