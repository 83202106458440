import { ChangeDetectionStrategy, Component,  input } from '@angular/core';
import { Transforms } from '@app/shared';

@Component({
  selector: 'form-dialog-title',
  templateUrl: './form-dialog-title.component.html',
  styleUrl: './form-dialog-title.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormDialogTitleComponent {
  
  public title = input.required<string>();

  public hasMandatoryFields = input(false, {
    transform: Transforms.toBoolean,
    alias: 'has-mandatory-fields'
  });
}