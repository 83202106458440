//angular imports
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  computed,
  input,
  signal
} from '@angular/core';

//local imports

import {
  BreadcrumbItemToggledMessage,
  BreadcrumbStateService,
  IBreadcrumbItemModel,
  Messenger
} from '@app/shared';

import {
  BreadcrumbChangedMessage,
} from '@app/shared';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbComponent implements OnDestroy {
  
  private internalBreadcrumbItems = signal<IBreadcrumbItemModel[]>([]);
  
  public breadcrumbName = input<string>();

  public breadcrumbItems = computed(() =>
  {
    let breadcrumbName = this.breadcrumbName();      
    
    let breadcrumbs = this.internalBreadcrumbItems();
    let tracker = this.breadcrumbStateService.getTracker(breadcrumbName);
    
    let unionDistinct = [...breadcrumbs, ...tracker.breadcrumbs].reduce((acc, current) => {
      if (!acc.some(item => item.id === current.id)) {
        acc.push(current);
      }
      return acc;
    }, []);

    return unionDistinct;
  });

  constructor(
    private messenger: Messenger,
    private breadcrumbStateService: BreadcrumbStateService) {

    this.messenger.subscribe(this, BreadcrumbItemToggledMessage, (msg) => {

      if (msg.handled) return;

      let breadcrumbName = this.breadcrumbName();

      if (breadcrumbName !== msg.breadcrumbName) return;
      
      let tracker = this.breadcrumbStateService.getTracker(breadcrumbName);

      if (!tracker.breadcrumbs.find((item) => item.id === msg.breadcrumbItem.id)) {        
        tracker.breadcrumbs.push(msg.breadcrumbItem);
        msg.breadcrumbItem.isChecked.set(true);//TODO:DIW:wrapper.
      }
      else {         
        tracker.breadcrumbs = tracker.breadcrumbs.filter((item) => item.id !== msg.breadcrumbItem.id);

        //TODO:DIW:Should be a breadcrumb wrapper
        //This is because isChecked could be used by everything,
        //And in other dialogs selection would interfere
        msg.breadcrumbItem.isChecked.set(false);
      }

      this.internalBreadcrumbItems.set([...tracker.breadcrumbs]);      

      //The relaybehaviour can trigger a re-toggle when the breadcrumb control is reused
      //To workaround, mark the msg as handled.
      msg.handled = true;

      this.messenger.send(new BreadcrumbChangedMessage(tracker.breadcrumbs, breadcrumbName));
    });
  }

  public removeBreadcrumb(val: IBreadcrumbItemModel) {    
    let breadcrumbName = this.breadcrumbName();
    let tracker = this.breadcrumbStateService.getTracker(breadcrumbName);

    tracker.breadcrumbs = tracker.breadcrumbs.filter((item) => item.id !== val.id);

    this.internalBreadcrumbItems.set([...tracker.breadcrumbs]); 
    val.isChecked.set(false);//TODO:DIW:wrapper.

    this.messenger.send(new BreadcrumbChangedMessage(tracker.breadcrumbs, this.breadcrumbName()));
  }
  
  ngOnDestroy(): void {
    this.messenger.unsubscribeByOwner(this);
  }
}
