@if (id(); as id) {
    @if (formGroup(); as formGroup) {
        <div
            [formGroup]="formGroup"
            [ngClass]="innerClass()"
            class="form-check d-flex align-items-center">
            <input
                type="checkbox"
                [checked]="checked()"
                (change)="$event.stopPropagation(); change.emit($event)"
                [id]="id"
                [formControlName]="id"
                class="form-check-input m-2" />

            <label
                [for]="id"
                [id]="id + '-label'"
                class="form-check-label">
                <ng-content></ng-content>
            </label>
        </div>
    } @else {
        <div
            [ngClass]="innerClass()"
            class="form-check d-flex align-items-center">
            <input
                type="checkbox"
                [checked]="checked()"
                (change)="$event.stopPropagation(); change.emit($event)"
                [id]="id"
                class="form-check-input m-2" />

            <label
                [for]="id"
                [id]="id + '-label'"
                class="form-check-label">
                <ng-content></ng-content>
            </label>
        </div>
    }
}
