//angular imports
import { NgModule, Injector } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { PlatformModule } from '@angular/cdk/platform';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

//font awesome imports
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { config } from '@fortawesome/fontawesome-svg-core';

config.autoAddCss = false;

//msal imports
import { 
    MsalModule, 
    MsalRedirectComponent
} from '@azure/msal-angular';

//local imports
import { 
    CoreServices, 
    ApplicationSecurityLifetime,
    CommunicationService 
} from './core';

import { Components } from './components';
import { PageComponents } from './pages';
import { SharedModule } from './shared';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';


export let InjectorInstance: Injector;

@NgModule({
    declarations: [
        AppComponent,
        ...Components,
        ...PageComponents,
    ],
    imports: [        
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        HttpClientModule,
        FormsModule,
        PlatformModule,
        FontAwesomeModule,
        MsalModule,
        
        //Local imports
        AppRoutingModule,
        SharedModule.forRoot()
    ],
    providers: [        
        ...CoreServices
    ],
    bootstrap: [
        AppComponent, 
        MsalRedirectComponent
    ]
})
export class AppModule {
    constructor(
        private injector: Injector,
        applicationSecurity: ApplicationSecurityLifetime,
        communicationService: CommunicationService) 
    {
        InjectorInstance = this.injector;

        applicationSecurity.initialize();
        communicationService.initialize();
    }
}