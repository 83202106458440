export * from './security';
export * from './services';
export * from './messages';

import { ApplicationSecurityServices } from './security';
import { ApplicationServices } from './services';

export const CoreServices = [
    ...ApplicationSecurityServices,
    ...ApplicationServices,
];