<span class="animate__animated animate__bounceIn font-12 d-flex align-items-center clip3 position-relative me-2 user-card fw-light text-light-blue border-light-blue corner p-2">
            
    @if(data(); as data)
    {
        @if(data.template)
        {
            <ng-container [ngTemplateOutlet]="data.template" 
                          [ngTemplateOutletContext]="{ $implicit: data, item: data }" />
        }
        @else {
            {{data.name()}}
        }
    }
    <span class="material-icons-sharp font-14 ms-3">close</span>
</span>