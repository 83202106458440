import { IBreadcrumbItemModel } from "../models/breadcrumb-item.model";

export class BreadcrumbChangedMessage
{
    public breadcrumbs: IBreadcrumbItemModel[] = [];
    public breadcrumbName: string = '';

    constructor();
    constructor(breadcrumbs: IBreadcrumbItemModel[], breadcrumbName: string);
    constructor(breadcrumbs?: IBreadcrumbItemModel[], breadcrumbName?: string) {

        if (breadcrumbs !== undefined)
        {
            this.breadcrumbs = breadcrumbs;
        }

        if (breadcrumbName !== undefined)
        {
            this.breadcrumbName = breadcrumbName;
        }
    }
}