//angular imports
import { 
  Component, 
  EventEmitter, 
  computed, input, Output, signal, 
  OnDestroy, 
  TemplateRef
} from '@angular/core';

//local imports
import { IListItem } from './list-item';
import { Transforms } from '@app/shared';

@Component({
  selector: 'list',
  templateUrl: './list.component.html',
  styleUrl: './list.component.scss'
})
export class ListComponent implements OnDestroy {

  private currentSelectedEntity?: IListItem[] = [];

  public allowSelect = input(true, {
    transform: Transforms.toBoolean
  });

  public allowMultiSelect = input(false, {
    transform: Transforms.toBoolean
  });

  public entities = input<IListItem[]>([]);
  public entityTitle = input<string>('');
  public searchValue = signal<string>('');
  public title = input<string>('');
  public template = input<TemplateRef<any>>(null);

  @Output() 
  public isEntitySelected = new EventEmitter<boolean>();

  @Output()
  public selectedEntity = new EventEmitter<IListItem>();

  public onSelectEntity(entity: IListItem) {
        
    if (!this.allowSelect()) return; 

    if (entity.isChecked()) {
      //remove selected entity from this.currentSelectedEntity
      this.currentSelectedEntity = this.currentSelectedEntity.filter(item => item !== entity);
      entity.select(false);

      this.isEntitySelected.emit(false);
      this.selectedEntity.emit(entity);//TODO:DIW:Should be selected entity changed.
      return;
    }
    
    let value = this.allowMultiSelect();

    if (!value && this.currentSelectedEntity.length > 0) {
      this.currentSelectedEntity[0].select(false);
      this.currentSelectedEntity = [];
    }

    this.currentSelectedEntity.push(entity);

    entity.select(true);

    //TODO:DIW:Not sure both these will be needed..
    this.isEntitySelected.emit(true);
    this.selectedEntity.emit(entity);
  }
  
  
  entitiesCopy = computed(() => this.entities()?.filter(item => {        
    if (item['Projects']) {
      return this.isTextMatching(this.searchValue(), item['Projects']) || this.searchCondition(item, this.searchValue());
    }
    return this.searchCondition(item, this.searchValue());
  }));

  searchEntity = (event: any, searchSide: string) => {
    this[searchSide].set(event.target.value);
  }

  isTextMatching(val, arr) {
    return arr.some(item => this.searchCondition(item, val));
  }

  searchCondition(item: IListItem, val) {

    //TODO:DIW:Every entity shown in the list needs a searchable property.
    try {

      return item.name()?.toLowerCase().includes(val.toLowerCase());
    }
    catch (e) {
      console.error(e);
      return true;
    }
  }

  ngOnDestroy(): void {

    //unselect.
    for (let entity of this.currentSelectedEntity) {
      if (entity.select) entity.select(false);
    }
  }
}
