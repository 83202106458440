<div *ngIf="!applicationSecurity.isIframe">
  <div>
    <header></header>
  </div>
  
  <router-outlet></router-outlet>
  
  <div>
    <footer></footer>
  </div>

</div>