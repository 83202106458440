const REGISTRY: { [name: string]: any } = {};

/**
 * @summary Decorator to mark a class as a notification type
 * @param The class name of the broadcast sent by the server 
 * @description Allows to get type information from a Notification class even after minification
 */
export function Notification(input: { name: string }) {

  // Return a class decorator function
  return function (target: Function) {        
    REGISTRY[input.name] = target;
    console.debug(`Registered message type ${name} with constructor ${target.name}`);
  };
}

/**
 * @summary Pulls the type information from the Notification decorator
 */
export class NotificationHelper {
  public static getNotificationType(input: { target: any}): string {
        
    for (const [key, cls] of Object.entries(REGISTRY)) {
      
      if (cls === input.target) {     
        console.debug(`Found ${key} in message registry with constructor ${cls.name}`);
        return key;
      }
    }

    return null;
  }
}

