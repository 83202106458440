@if(currentItem(); as entity)
{       
    <div class="container">
        <div class="row" [ngClass]="isSelectable() && entity.isChecked() ? 'text-light-blue fw-normal' : 'text-white fw-light'">  

            <div class="p-0 d-flex align-items-center">
                @if(isSelectable()){

                    <div>

                        @if(entity.isChecked())
                        {
                            <span class="material-icons-sharp font-20 text-light-blue me-2" style="line-height:1.6">check_circle</span>
                        }
                        @else
                        {
                            <span class="material-icons-sharp font-20 text-light-blue me-2" style="line-height:1.6">radio_button_unchecked</span>
                        }

                    </div>
                }            
            
                
                <div class="flex-grow-1">
                    @if(template(); as outputTemplate)
                    {
                        <ng-container [ngTemplateOutlet]="outputTemplate" 
                                    [ngTemplateOutletContext]="{ $implicit: entity, item: entity }" />                            
                    }
                    @else
                    {
                        {{entity.name()}}
                    }
                </div>

            </div>
         </div>
    </div>
}