import { DefaultApiRetryPolicy } from "./default-api-retry.policy";

/**
 * @summary Provides constants for API requests
 */
export class ApiConstants
{
    public static readonly DefaultTimeout: number = 30000;
    public static readonly RetryPolicy: DefaultApiRetryPolicy = new DefaultApiRetryPolicy();

    public static readonly DefaultHeaders: any = {
        'Content-Type': 'application/json',                             
        'Accept': 'application/json'
    }
}