//Angular imports
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';

//syncfusion imports
import { registerLicense } from '@syncfusion/ej2-base';

//Local imports
import { SecurityConfig } from '@app/core';
import { AppModule } from '@app/app.module';
import { environment } from '@env/environment';

if (environment.production) {
  enableProdMode();
}

registerLicense('Ngo9BigBOggjHTQxAR8/V1NAaF5cWWJCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWX5fcXRcRGNeUUN/V0A=');

//Begin bootstrapping application
//app.config.json contains information about how to connect
fetch('assets/app.config.json')
  .then(response => { 
    return response.json();    
  })
  .then(config => {
    
    console.info(`Environment: ${environment.production ? 'production' : 'development'}`);
    console.info(`Connecting to: '${config.applicationUri}`);

    return platformBrowserDynamic(
    [
      {
        provide: SecurityConfig,
        useValue: config          
      }
    ])
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
  });
