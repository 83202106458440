//angular imports
import { 
  Component, 
  EventEmitter,
  Output,
  TemplateRef, 
  input 
} from '@angular/core';

//local imports
import { Transforms, IListItem } from '@app/shared';


@Component({
  selector: 'item-small-generic',
  templateUrl: './item-small-generic.component.html',
  styleUrl: './item-small-generic.component.scss'
})
export class ItemSmallGenericComponent {      
  public currentItem = input<IListItem>(null);
  public isSelectable = input(false, {
    transform: Transforms.toBoolean
  });
  
  public template? = input<TemplateRef<any>>(null);
  
  @Output() 
  public itemCheckedEmitter = new EventEmitter();

  toggle() {
    let toggled = !this.currentItem().isChecked();

    this.currentItem().isChecked.set(toggled);

    //TODO:DIW:Not sure if this is required:
    this.itemCheckedEmitter.emit(this.currentItem);
  }
}

