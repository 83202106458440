import {  
  ChangeDetectionStrategy, 
  Component, 
  EventEmitter, 
  Output, 
  input 
} from '@angular/core';

@Component({
  selector: 'is-busy-button',
  templateUrl: './is-busy-button.component.html',
  styleUrl: './is-busy-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IsBusyButtonComponent {
    
  public disabled = input<boolean>(true);
  public isBusy = input<boolean>(false);
  public css = input<string>(null);
  
  @Output() 
  public execute = new EventEmitter();

  public submit(e: Event)
  {
    e.stopPropagation(); 
    this.execute.emit();
  }

}