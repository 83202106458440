export class SetPreviousUrlMessage
{
    public Url: string;

    constructor();
    constructor(url: string);
    constructor(url?: string) {

        if (url !== undefined)
        {
            this.Url = url;
        }
    }
}