<div class="container-fluid">
  <button class="mb-3 btn btn-primary me-3" (click)="hideColumn('department', 'name')">hideColumn 'name' from columnGroup 'department'</button>
  <button class="mb-3 btn btn-primary" (click)="hideColumn('Room', 'subCode')">hideColumn 'sub code' from columnGroup 'room'</button>
  <div class="row">
      @if (table(); as table) {
        <!-- <button (click)="hideColumn('orderId')">hgvgv</button> -->
        @for (columnGroup of table.columngroups; track columnGroup; let i = $index) {
          @if(columnGroup.isSelected) {
            <div class="col">
              <div class="">
                <div style="background-color: rgba(15, 28, 61, 0.75) !important;" class="w-100 clip3 font-12 rounded-0 px-3 py-2 text-start mb-2 text-white">{{columnGroup.id | uppercase}}</div>
              </div>
              <ejs-grid [dataSource]="columnGroup.rows" allowSorting="true">
                <e-columns>
                    @for (column of columnGroup.columns; track column) {
                      <e-column width="{{ column.width() }}">
                        <ng-template #headerTemplate let-data>
                          <div role="button" (click)="sortColumn(column)">
                            {{ column.columnText() }}
                          </div>
                        </ng-template>
                        <ng-template #template let-data>
                          <div class="capsule font-14 text-faded">
                            {{ data[column.id].value }}
                          </div>
                        </ng-template>
                      </e-column>
                    }
                </e-columns>
              </ejs-grid>
            </div> 
          }
        }
      }
  </div>
</div>
