//angular imports
import {
  ChangeDetectionStrategy,
  Component,
  InputSignal,
  input
} from '@angular/core';


@Component({
  selector: 'required-validation-error',
  templateUrl: './required-validation-error.component.html',
  styleUrl: './required-validation-error.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequiredValidationErrorComponent  {

  public label: InputSignal<string> = input.required<string>();
  
  constructor() {}  
}
