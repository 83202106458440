<div
    [routerLink]="routerLink()"
    [routerLinkActive]="'active'"
    class="p-2 fw-light">
    <img
        [src]="unselectedImageSrc()"
        class="me-2 {{unselectedCss()}}" />
    <img
        [src]="selectedImageSrc()"
        class="me-2 {{selectedCss()}}" />
    <span>{{title()}}</span>
</div>