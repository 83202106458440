@if(breadcrumbItems(); as breadcrumbItems)
{
    @if(breadcrumbItems.length) {
        <div class="px-1 pb-3 d-flex">
            @for (breadcrumbItem of breadcrumbItems; track breadcrumbItem)
            {
                <breadcrumb-item [id]="breadcrumbItem.id" 
                                 [data]="breadcrumbItem" 
                                 (click)="removeBreadcrumb(breadcrumbItem)"/>                
            }
        </div>
    }
}