export * from './data';
export * from './atoms';
export * from './molecules';
export * from './organisms';

import { FilterAtoms } from './atoms';
import { FilterMolecules } from './molecules';
import { FilterOrganisms } from './organisms';

export const FilterComponents = [
    ...FilterAtoms,    
    ...FilterMolecules,
    ...FilterOrganisms
]