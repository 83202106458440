//angular imports
import { HTTP_INTERCEPTORS } from '@angular/common/http';

//msal imports
import { 
    MSAL_GUARD_CONFIG, 
    MSAL_INSTANCE, 
    MSAL_INTERCEPTOR_CONFIG, 
    MsalBroadcastService, 
    MsalGuard, 
    MsalInterceptor, 
    MsalService 
} from '@azure/msal-angular';

//local imports
import { 
    ApplicationSecurity, 
    ApplicationSecurityLifetime 
} from './application-security';

export * from './application-security';

export const ApplicationSecurityServices = [
    ApplicationSecurity,
    ApplicationSecurityLifetime,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: MsalInterceptor,
        multi: true
    },
    {
        provide: MSAL_INSTANCE,
        useFactory: (security: ApplicationSecurity) => {
            return security.MSALInstanceFactory();
        },
        deps: [ApplicationSecurity]
    },
    {
        provide: MSAL_GUARD_CONFIG,
        useFactory: (security: ApplicationSecurity) => {
            return security.MSALGuardConfigFactory();
        },
        deps: [ApplicationSecurity]
    },
    {
        provide: MSAL_INTERCEPTOR_CONFIG,
        useFactory: (security: ApplicationSecurity) => {
            return security.MSALInterceptorConfigFactory();
        },
        deps: [ApplicationSecurity]
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
]