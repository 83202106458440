//angular imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { 
    FormsModule, 
    ReactiveFormsModule 
} from '@angular/forms';

import { PortalModule } from '@angular/cdk/portal';
import { RouterModule } from '@angular/router';

//syncfusion imports
import { GridModule } from '@syncfusion/ej2-angular-grids';

//ngx imports
import { AccordionModule } from 'ngx-bootstrap/accordion';

//local imports

import { 
    Messenger 
} from './data';

import { 
    SharedComponentServices,
    SharedComponents
} from './components';

@NgModule({
    declarations: [
        ...SharedComponents
    ],
    imports: [
        CommonModule,        
        FormsModule,
        ReactiveFormsModule,
        PortalModule,        
        AccordionModule,
        RouterModule,
        GridModule
    ],
    exports: [
        ...SharedComponents,
        GridModule
    ] 
})
export class SharedModule {    
    static forRoot() {
        return {
          ngModule: SharedModule,
          providers: [ Messenger, ...SharedComponentServices ]
        };
      }
}