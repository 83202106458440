//angular imports
import { ChangeDetectionStrategy, Component } from '@angular/core';

//local imports
import { ApplicationSecurityLifetime } from './core';

@Component({
  selector: 'root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
  title = 'modutecture';

  constructor(public applicationSecurity: ApplicationSecurityLifetime) { 
    
   }
}
