//angular imports
import { Signal, WritableSignal, computed, signal } from "@angular/core";

//local imports
import { Func0 } from "@app/shared";

type CommandModelConstructorArgs = {
    executeAction?: Func0<Promise<void>>, 
    isValid?: Signal<boolean>
}

export class CommandModel
{   
    private isValidInternal: WritableSignal<boolean|null> = signal<boolean|null>(null);
    private isValidExternal: Signal<boolean>;

    public isBusy: WritableSignal<boolean> = signal<boolean>(false);
    
    private executeAction: Func0<Promise<void>>;
    
    public isValid: Signal<boolean> = computed(() => {

        if (this.isValidExternal) 
        {
            let result = this.isValidExternal();
            
            return result;
        }

        let internalResult = this.isValidInternal();
        
        return internalResult
    });

    constructor(input: CommandModelConstructorArgs) {

        if (input.executeAction) {
            this.executeAction = input.executeAction;
        }
        
        if (input.isValid) {
            this.isValidExternal = input.isValid;
        }        
    }
    
    public setValid(value: boolean) {
        this.isValidInternal.set(value);
    }

    public async execute() {

        try {
            this.isBusy.set(true);
            await this.executeAction();

        }
        finally {
            this.isBusy.set(false);            
        }
    }
}