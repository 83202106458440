//angular imports
import {
  ChangeDetectionStrategy,
  Component,
  InputSignal,
  computed,
  input
} from '@angular/core';

//local imports
import {
  FilterGroupModel
} from '@app/shared';

@Component({
  selector: 'filter',
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterComponent  {

  public filterGroups: InputSignal<FilterGroupModel[]> = input<FilterGroupModel[]>(null);
  
  public filtersNode = computed(() => {
    return new FilterGroupModel({
      id: "filter",
      title: "Filters",
      depth: 0,
      children: this.filterGroups()
    });
  });

}
