//angular imports
import { Injectable, Signal } from "@angular/core";
import { toSignal } from '@angular/core/rxjs-interop';

//rxjs imports
import { ReplaySubject, Subscription } from "rxjs";

//local imports
import { Dictionary, NotificationHelper } from "@app/shared";

interface SubscriptionEntry {
    owner: any;
    notificationType: any;
    subscription: Subscription;
}

/**
 * @summary A service that allows components to communicate with each other
 * @description This service uses signals to allow components to communicate with each other.
 */
@Injectable({
    providedIn: 'root'
})
export class Messenger {

    // Create a subject for each event type
    private signals: Dictionary<string, ReplaySubject<any>> = {};
    private subscriptions: SubscriptionEntry[] = [];

    //Create an entry for the type of notification, so we can create it again from a notification name
    private eventTypes: Dictionary<string, any> = {};

    /**
     * @summary Send a message to all subscribers of the given type
     * @param message The message object to send
     */
    public send<TMessageType extends object>(message: TMessageType) {

        let eventName = NotificationHelper.getNotificationType({ target: message.constructor }) ?? (<any>message.constructor).name;
        this.getSubjectImpl(eventName).next(message);
    }

    /**
     * @summary Subscribes based on the notification type
     * @param notificationType The notification type to subscribe to     
     * @param delegate The delegate to call when the notification is received
     */
    public subscribe<TNotificationType>(
        owner: any,
        notificationType: { new(...args: any[]): TNotificationType },
        delegate: (value: TNotificationType) => void): void {
        
        let subscription = this.getSubject(notificationType).subscribe(delegate);

        const subscriptionEntry: SubscriptionEntry = {
            owner: owner,
            notificationType: notificationType,
            subscription: subscription
        };

        this.subscriptions.push(subscriptionEntry);

    }

    /**
     * @summary Gets a subject for the given notification type
     * @param notificationType: The notification type covered by the subject 
     * @returns a ReplaySubject for the given notification type
     */
    public getSubject<TNotificationType>(
        notificationType: { new(...args: any[]): TNotificationType }): ReplaySubject<any> {

        const eventName = NotificationHelper.getNotificationType({ target: notificationType }) ?? (<any>notificationType).name;
        this.eventTypes[eventName] = notificationType;
        return this.getSubjectImpl(eventName);
    }

        /**
     * @summary Gets a signal for the given notification type
     * @param notificationType: The notification type covered by the subject 
     * @returns a Signal for the given notification type
     */
    public getSignal<TNotificationType>(
        notificationType: { new(...args: any[]): TNotificationType }): Signal<any> {        

        return toSignal(this.getSubject(notificationType));
    }

    private getSubjectImpl(eventName: string) : ReplaySubject<any> {

        if (!this.signals[eventName]) {
            this.signals[eventName] = new ReplaySubject(null);
        }
        return this.signals[eventName];
    }


    /**
     * @summary Unsbuscribes from a signal based on the owner and notification type
     * @param owner The owner of the subscription
     * @param notificationType The type of notification to unsubscribe from
     */
    public unsubscribeByOwnerAndType(owner: any, notificationType: any) {
        const subscriptionEntry = this.findSubscriptionEntry(owner, notificationType);

        if (subscriptionEntry) {
            subscriptionEntry.subscription.unsubscribe();
            this.removeSubscriptionEntry(subscriptionEntry);
        }
    }

    /**
     * @summary Unsubscribes all subscriptions for a given owner
     * @param owner The owner of the subscription
     */
    public unsubscribeByOwner(owner: any) {
        const subscriptionEntries = this.subscriptions.filter(x => x.owner === owner);

        subscriptionEntries.forEach(entry => {
            entry.subscription.unsubscribe();
            this.removeSubscriptionEntry(entry);
        });
    }
    
    /**
     * @summary Gets the notification type from the notification name
     */
    public getType(notificationType: string) {        
        return this.eventTypes[notificationType];
    }

    // Helper methods

    private findSubscriptionEntry(owner: any, notificationType: any): SubscriptionEntry | undefined {
        return this.subscriptions.find(entry => entry.owner === owner && entry.notificationType === notificationType);
    }

    private removeSubscriptionEntry(entry: SubscriptionEntry) {
        const index = this.subscriptions.indexOf(entry);
        if (index !== -1) {
            this.subscriptions = this.subscriptions.splice(index, 1);
        }
    }



}