import { WritableSignal, signal } from "@angular/core";

export class NodeModel<T> {
    public id: string;
    
    public data: WritableSignal<T>;    
    public children: WritableSignal<NodeModel<T>[]>;
    public metadata?: { [key: string]: string };
    public isRoot: WritableSignal<boolean>;
    public depth: WritableSignal<number>;

    constructor(input: { id?: string, data?: T, depth: number, children?: NodeModel<T>[], metadata?: { [key: string]: string } }) {
        this.id = input.id;
        this.data = signal<T>(input.data);
        this.depth = signal<number>(input.depth);
        this.children = signal<NodeModel<T>[]>(input.children);
        this.metadata = input.metadata;
    }
}