//angular imports
import { 
  ChangeDetectionStrategy, 
  Component, 
  input 
} from '@angular/core';

//local imports
import { Transforms } from '@app/shared';

@Component({
  selector: 'filter-group-header',
  templateUrl: './filter-group-header.component.html',
  styleUrl: './filter-group-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterGroupHeaderComponent {

  public groupName = input<string>();  
  
  public isRoot = input(false, {
    transform: Transforms.toBoolean
  });

}
