<div class="w-100 position-fixed">
    <div class="container-fluid py-3">
        <div class="row m-0 row m-0">
            <div class="d-flex align-item-center justify-content-between flex">
                <div class="logo">
                    <img src="assets/images/logo.png">
                </div>
                <div class="d-flex align-items-center">
                    <span class="search d-flex bg-search m-2 clip3">
                        <div class="form-group">
                            <input type="text" class="form-control bg-transparent border-0 text-white ps-5 px-3 py-2">
                        </div>
                        <span class="material-icons-sharp font-24 position-absolute ms-3 mt-2 text-light-blue">search</span>
                    </span>
                    <span>
                        <span class="material-icons-sharp font-24 mt-1 text-light-blue ms-3">notifications_none</span> 
                    </span>
                </div>
            </div>
        </div>
    </div>

</div>