@if (data(); as filterGroupItem) {
    <accordion [isAnimated]="true">
        <accordion-group
            [isOpen]="true"
            [panelClass]="filterGroupItem.depth() === 0 ? 'accord' : filterGroupItem.id + '-accord'"
            [id]="filterGroupItem.id">
            <filter-group-header
                accordion-heading
                [isRoot]="filterGroupItem.depth() === 0"
                [id]="filterGroupItem.id + '-header'"
                [groupName]="filterGroupItem.title()" />

            @for (child of filterGroupItem.children(); track child) {
                <presenter [data]="child" />
            }
        </accordion-group>
    </accordion>
}
