//misc imports
import { Observable, catchError, lastValueFrom, takeUntil, throwError, timeout } from 'rxjs';
import polly from 'polly-js';

//local imports
import { ApiConstants, FaultHelper, HttpResult } from '@app/shared';

/**
 * @summary Provides helper methods for invoking HTTP requests with Polly with a default retry policy
 */
export class PollyHttpHelper
{
    public static Invoke<T>(
        httpMethod: string, 
        action: () => Observable<T>): HttpResult<T>
    {
        let  result = new HttpResult<T>();
        
        result.Response = polly()
            .handle(ex => ApiConstants.RetryPolicy.handleError(httpMethod, ex))
            .waitAndRetry(ApiConstants.RetryPolicy.maxRetryAttempts)            
            .executeForPromise(() => 
                lastValueFrom(
                action()
                    .pipe(takeUntil(result.Cancellation))
                    .pipe(timeout(ApiConstants.DefaultTimeout))
                    .pipe(catchError((error: any) => {                        	
                        return throwError(() => {                            
                            if (error.status !== 400) throw error;

                            let fault = FaultHelper.createFaultInstance({ data: error.error });
                            throw fault ?? error;                            
                        });
                    }))
            ));

        return result;
    }
}